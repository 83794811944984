<template>
  <div class="main_container">
      <p class="title title-size">Neowalls community guidelines (January 2022)</p>
      <img src="../assets/icons/neowalls.png" alt="neowalls" class="logo-size">
      <div class="text-adjust">
          <p class="title subtitle-size">1. Neowalls mission</p>
          <p class="text text-adjust">
              Here at The X, we create platforms that inspire people for the good. And 
              Neowalls is a perfect example of that. With neowalls, we are on a huge mission 
              to create a really enjoyable and amazing community of creative, enthusiastic and 
              wonderful people that share love for good quality imagery. But we know for a fact 
              that this mission is impossible for us Neowalls alone to achieve. It is all up for you, 
              creative people. By working together we will all be able to bring together a huge community 
              of people with different tastes, ages, and spiritual mindsets and much more. But in order to 
              keep it an amazing and an enjoyable experience for all people with no exception at all, we all 
              need to work with these community guidelines in mind.
          </p>

          <p class="title subtitle-size">2. Shared content safety</p>
          <p class="text text-adjust">
              Neowalls is definitely not a place for sharing explicite, sexual or violant content. And the users that try to promote such content may face some consequences defined in the last section. We will help you avoid sharing such content by clearly stating all of it.
          </p>
          <p class="title text-adjust">Adult/Sexual content</p>
          <ul>
              <li class="text text-adjust">
                  Nude/Half nude photos of people (both sexes are not allowed).
              </li>
              <li class="text text-adjust">
                  Photos of people that are taken in angles that show sexual intent.
              </li>
              <li class="text text-adjust">
                  Photos that depict sexual activity.
              </li>
          </ul>

          <p class="title text-adjust">Violent content</p>
          <ul>
              <li class="text text-adjust">
                  Photos that contain violent scenes such as (photos that contain blood as a main subject).
              </li>
              <li class="text text-adjust">
                  Photos of weapons that are pure art (example: engraved art in a small knife, custom game weaponry for cosplays) are allowed, but any photo of a weapon that shows any intent of violence is not allowed.
              </li>
              <li class="text text-adjust">
                  Photos that show illegal interactions.
              </li>
              <li class="text text-adjust">
                  Photos that show any hateful/war symbole (example: nazi symbole).
              </li>
              <li class="text text-adjust">
                  Photos that encourage any violence against living beings.
              </li>
          </ul>

          <p class="text text-adjust">
              Note that all of the above mentioned content applies in case of captured pictures, drawings and anime/cartoon content.
          </p>

          <p class="title text-adjust">Quality content</p>
          <p class="text text-adjust">
              We do our best to not allow the users to upload such kinds of the above-mentioned photos before they even hit the upload button, and we will continue to push the monitoring after the upload so that we can create a safe environment for everyone.
          </p>
          <p class="text text-adjust">
              In order for the photo detection to be as close as possible to perfect, we encourage you creators to upload photos that are above average quality. This does not mean that your phone’s photos are not encouraged, it just means that your photo -no matter what device is used to create/capture it- needs to be clear enough. As a rule of thumb, if you can identify at a first glance what’s in your picture, then, our application can detect what’s in it, so you can go ahead and share it with the world.
          </p>

          <p class="title subtitle-size">3. Content distribution and pricing</p>
          <p class="text text-adjust">
              At neowalls -for now-, priced content does not exist. This means that -sadly- our dear creators cannot sell their photos on the platform -yet-. Neowalls is currently working on bringing this feature in the near future. For now, we encourage users to share their own original content for free while gaining support and redirection to their other social platforms from profile visitors where they can sell freely.
          </p>
          <p class="text text-adjust">
              We also encourage creators to not share any content that is not their own original content, or content that they have no right to share even though the Neowalls application is secure enough to keep the images inside the app only, it is better to not share content that you do not own (just put yourself in the place of the creator of that image).
          </p>

          <p class="title subtitle-size">4. Reporting content</p>
          <p class="text text-adjust">
              Starting from Neowalls V1.2, content reporting will be available for everyone on the platform. This means that you as a Neowalls user can work with us on this mission to keep the platform as clean as it possibly can.  Any logged in user can report a photo if they find that it violates content safety or they identified that the user that shared a specific photo is not the owner or does not have any right to redistribute it.
          </p>
          <p class="text text-adjust">
              After sending your report, we at Neowalls will verify the reported photo manually, and will take the appropriate action upon it. It is highly encouraged to not hate/spam-report anyone because this can backfire at you. Because we trust your judgment and the least you can do is live up to it. So let’s help each other keep it a clean place.
          </p>

          <p class="title subtitle-size">5. Social links safety</p>
          <p class="text text-adjust">
              Neowalls highlights your social links by showing them in the front of your profile, so that you can benefit from the users that enjoy your content at our platform to continue to enjoy your content on other platforms as well, and might possibly give you the support that you need to keep up the good work.
          </p>
          <p class="text text-adjust">
              However, using those highlights to share malicious links or links that lead to a platform other than the one that a specific highlight suggests is definitely not encouraged. In fact, we encourage you to share only your social username without the link handle (example: Instagram username: neowalls not https://intagram.com/neowalls), And because Neowalls provides a direct copy feature, users can copy your username and head directly to the specified platform and paste it. This increases your chances of gaining better support/followers on other platforms.
          </p>

          <p class="title subtitle-size">6. Content support and interaction</p>
          <p class="text text-adjust">
              We at neowalls believe that supporting someone is taking action for them, that’s why we added a staring system in the app in the first place. So if you see a piece of content that you really liked, we highly encourage you to star it. This has benefits, one for you and the other is for the creator. When you star a post, you are actually adding it to your favorite list, and this means that this wallpaper is easier for you to find, even across any number of devices that you might be using for Neowalls. This also adds one star to the creator stars count, the higher a profile has stars the more enjoyable their content is.
	We just need to warn you about the possibility of spamming the interaction, this includes staring/unstaring the same post multiple times in a very short timespan.
          </p>

          <p class="title subtitle-size">7. Data and privacy related to content</p>
          <p class="text text-adjust">
              We care about your privacy inside Neowalls, that’s why we never collect your personal data, the only thing we collect is crash reports from app installations and this actually will help us upgrade your experience even more in the future, we know that you have your concerns about your data, but Neowalls is really a different story from any other social media platform. Here we only need to tell you the obvious, which is that your profile is shown to users inside neowalls via the posts you share (the basic information that you see in a typical post). None of the other actions that exist inside the app except sharing photos, show your profile to the users (which is super obvious right?). 
          </p>

          <p class="title subtitle-size">8. Consequences of not complying to the guidelines</p>
          <p class="text text-adjust">
              First of all, let’s hope that you read all the above guidelines carefully. Now, we believe that being harsh with our users from the first violation is not at all a solution. Because a first violation can possibly be not on purpose (only in case you did not verify what you were trying to post or you did not read these guidelines which we can somehow understand).
          </p>
          <p class="text text-adjust">
              Neowalls moderates content a bit differently, when the user first selects the photo to upload, Neowalls takes some time to process it and if it identifies violating content it will not let the user upload it. If the processing did not detect the content and gets shared, another layer of the algorithm over the cloud will reprocess it and try to detect it, this one results in deleting that piece of content and applying a consequence of the mentioned below. The same case applies to our third layer which is the dear users of neowalls themselves reporting such content but instead of an algorithm processing it, we process it manually as we mentioned in section 4.
          </p>
          <p class="text text-adjust">
              Now, let’s make it clear for you how the consequences will work :
          </p>
          <ul>
              <li class="text-adjust">
                  <p class="title">First violation</p>
                  <p class="text text-adjust">A user that violates the guidelines for the first time, will get a warning with a suggestion to read the community guidelines (as we assume that they did not read them before).</p>
              </li>
              <li class="text-adjust">
                  <p class="title">Second violation</p>
                  <p class="text text-adjust">A user that violates the guidelines a second time, will get a red flag on his account for 30 days (it is a red ribbon on his profile that is visible only for him, to remind him that he has only one last chance to correct his way of content sharing and increasing his star count).</p>
              </li>
              <li class="text-adjust">
                  <p class="title">Third violation</p>
                  <p class="text text-adjust">if a user violates the guidelines for a third time (even though we warned them two times and gave them suggestions) get their account banned from the platform with one possible direct discussion with us over the problem (if they see that we, combined with three layers of algorithm/user moderation are the ones wrong for some reason).</p>
              </li>
          </ul>
          <p class="text text-adjust">
              Now, some malicious users might be thinking to use two violations each 30 days and like that they will avoid getting their accounts banned. Well unfortunately, any account that gets more than five warnings (counting from the first warning ever) will get banned with no possible discussion.
          </p>
          <br><br><br>
      </div>

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.main_container{
    text-align: center;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
}

.logo-size{
    width: 20%;
    margin: 0px;
}

.title-size{
    font-size: 1.4em;
    margin-bottom: 0px;
}

.subtitle-size{
    font-size: 1.2em;
}

.text-adjust{
    text-align: justify;
}

@media only screen and (max-width: 720px){
    .logo-size{
        width: 60%;
        margin: 10px;
    }

    .text-adjust{
        text-align: left;
    }
}


</style>